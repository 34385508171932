exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-affiliates-mdx": () => import("./../../../src/pages/affiliates.mdx" /* webpackChunkName: "component---src-pages-affiliates-mdx" */),
  "component---src-pages-callback-tsx": () => import("./../../../src/pages/callback.tsx" /* webpackChunkName: "component---src-pages-callback-tsx" */),
  "component---src-pages-cots-tsx": () => import("./../../../src/pages/cots.tsx" /* webpackChunkName: "component---src-pages-cots-tsx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-purchase-history-tsx": () => import("./../../../src/pages/purchase-history.tsx" /* webpackChunkName: "component---src-pages-purchase-history-tsx" */),
  "component---src-pages-roadmap-figma-bundle-tsx": () => import("./../../../src/pages/roadmap/figma-bundle.tsx" /* webpackChunkName: "component---src-pages-roadmap-figma-bundle-tsx" */),
  "component---src-pages-sales-black-friday-2023-tsx": () => import("./../../../src/pages/sales/black-friday-2023.tsx" /* webpackChunkName: "component---src-pages-sales-black-friday-2023-tsx" */),
  "component---src-pages-sales-diwali-tsx": () => import("./../../../src/pages/sales/diwali.tsx" /* webpackChunkName: "component---src-pages-sales-diwali-tsx" */),
  "component---src-pages-shopify-ringer-tsx": () => import("./../../../src/pages/shopify/ringer.tsx" /* webpackChunkName: "component---src-pages-shopify-ringer-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blogs-09-detect-shopify-theme-guide-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/blogs/09-detect-shopify-theme-guide.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blogs-09-detect-shopify-theme-guide-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blogs-10-change-shopify-theme-without-losing-content-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/blogs/10-change-shopify-theme-without-losing-content.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blogs-10-change-shopify-theme-without-losing-content-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blogs-12-ultimate-guide-to-seamlessly-updating-your-shopify-theme-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/blogs/12-ultimate-guide-to-seamlessly-updating-your-shopify-theme.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blogs-12-ultimate-guide-to-seamlessly-updating-your-shopify-theme-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blogs-13-how-many-companies-use-shopify-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/blogs/13-how-many-companies-use-shopify.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blogs-13-how-many-companies-use-shopify-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blogs-14-shopify-prohibited-products-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/blogs/14-shopify-prohibited-products.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blogs-14-shopify-prohibited-products-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blogs-15-launching-eyewear-store-optics-shopify-guide-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/blogs/15-launching-eyewear-store-optics-shopify-guide.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blogs-15-launching-eyewear-store-optics-shopify-guide-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blogs-16-seo-optimization-for-eyewear-stores-using-optics-shopify-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/blogs/16-seo-optimization-for-eyewear-stores-using-optics-shopify.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blogs-16-seo-optimization-for-eyewear-stores-using-optics-shopify-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blogs-17-solve-eyewear-retail-challenges-with-optics-shopify-theme-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/blogs/17-solve-eyewear-retail-challenges-with-optics-shopify-theme.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blogs-17-solve-eyewear-retail-challenges-with-optics-shopify-theme-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blogs-18-shopify-vs-shopify-plus-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/blogs/18-shopify-vs-shopify-plus.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blogs-18-shopify-vs-shopify-plus-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blogs-19-future-of-eyewear-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/blogs/19-future-of-eyewear.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blogs-19-future-of-eyewear-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blogs-20-manage-your-shopify-order-delivery-with-ease-using-shipday-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/blogs/20-manage-your-shopify-order-delivery-with-ease-using-shipday.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blogs-20-manage-your-shopify-order-delivery-with-ease-using-shipday-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blogs-21-shopify-omnichannel-apps-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/blogs/21-shopify-omnichannel-apps.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blogs-21-shopify-omnichannel-apps-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blogs-22-shopify-upselling-tips-to-improve-revenue-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/blogs/22-shopify-upselling-tips-to-improve-revenue.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blogs-22-shopify-upselling-tips-to-improve-revenue-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blogs-23-shopify-tips-boost-aov-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/blogs/23-shopify-tips-boost-aov.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blogs-23-shopify-tips-boost-aov-mdx" */),
  "component---src-templates-cots-details-tsx-content-file-path-content-themes-doctor-appointment-mdx": () => import("./../../../src/templates/cots-details.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/doctor-appointment.mdx" /* webpackChunkName: "component---src-templates-cots-details-tsx-content-file-path-content-themes-doctor-appointment-mdx" */),
  "component---src-templates-shopify-detail-tsx-content-file-path-content-themes-coursewhiz-shopify-mdx": () => import("./../../../src/templates/shopify-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/coursewhiz-shopify.mdx" /* webpackChunkName: "component---src-templates-shopify-detail-tsx-content-file-path-content-themes-coursewhiz-shopify-mdx" */),
  "component---src-templates-shopify-detail-tsx-content-file-path-content-themes-electronix-shopify-mdx": () => import("./../../../src/templates/shopify-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/electronix-shopify.mdx" /* webpackChunkName: "component---src-templates-shopify-detail-tsx-content-file-path-content-themes-electronix-shopify-mdx" */),
  "component---src-templates-shopify-detail-tsx-content-file-path-content-themes-groxery-shopify-mdx": () => import("./../../../src/templates/shopify-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/groxery-shopify.mdx" /* webpackChunkName: "component---src-templates-shopify-detail-tsx-content-file-path-content-themes-groxery-shopify-mdx" */),
  "component---src-templates-shopify-detail-tsx-content-file-path-content-themes-medical-shopify-mdx": () => import("./../../../src/templates/shopify-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/medical-shopify.mdx" /* webpackChunkName: "component---src-templates-shopify-detail-tsx-content-file-path-content-themes-medical-shopify-mdx" */),
  "component---src-templates-shopify-detail-tsx-content-file-path-content-themes-optics-shopify-mdx": () => import("./../../../src/templates/shopify-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/optics-shopify.mdx" /* webpackChunkName: "component---src-templates-shopify-detail-tsx-content-file-path-content-themes-optics-shopify-mdx" */),
  "component---src-templates-shopify-detail-tsx-content-file-path-content-themes-wosa-shopify-mdx": () => import("./../../../src/templates/shopify-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/wosa-shopify.mdx" /* webpackChunkName: "component---src-templates-shopify-detail-tsx-content-file-path-content-themes-wosa-shopify-mdx" */),
  "component---src-templates-tag-page-tsx": () => import("./../../../src/templates/tag-page.tsx" /* webpackChunkName: "component---src-templates-tag-page-tsx" */),
  "component---src-templates-technology-page-tsx": () => import("./../../../src/templates/technology-page.tsx" /* webpackChunkName: "component---src-templates-technology-page-tsx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-course-whiz-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/course-whiz.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-course-whiz-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-couse-whiz-bundle-sf-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/couse-whiz-bundle-sf.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-couse-whiz-bundle-sf-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-e-commerce-figma-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/e-commerce-figma.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-e-commerce-figma-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-electronix-figma-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/electronix-figma.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-electronix-figma-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-eye-cart-bundle-sf-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/eye-cart-bundle-sf.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-eye-cart-bundle-sf-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-eye-cart-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/eye-cart.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-eye-cart-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-medical-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/medical.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-medical-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-doggy-stickers-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/open-source/doggy_stickers.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-doggy-stickers-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-ecommerce-shop-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/open-source/ecommerce-shop.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-ecommerce-shop-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-gridsome-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/open-source/gridsome.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-gridsome-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-haru-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/open-source/haru.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-haru-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-iceo-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/open-source/iceo.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-iceo-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-misiki-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/open-source/misiki.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-misiki-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-radon-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/open-source/radon.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-radon-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-rafcart-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/open-source/rafcart.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-rafcart-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-sanity-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/open-source/sanity.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-sanity-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-shuuz-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/open-source/shuuz.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-shuuz-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-starter-store-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/open-source/starter_store.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-starter-store-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-svelte-litekart-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/open-source/svelte-litekart.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-svelte-litekart-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-the-lama-shop-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/open-source/the-lama-shop.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-open-source-the-lama-shop-mdx" */),
  "component---src-templates-theme-detail-tsx-content-file-path-content-themes-wosa-mdx": () => import("./../../../src/templates/theme-detail.tsx?__contentFilePath=/builds/truemark/all-brainy/polo-themes-marketing/content/themes/wosa.mdx" /* webpackChunkName: "component---src-templates-theme-detail-tsx-content-file-path-content-themes-wosa-mdx" */)
}

